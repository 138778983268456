import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [3,[2]],
		"/(app)/auth": [9,[2]],
		"/(app)/downloads": [10,[2]],
		"/(app)/home": [11,[2]],
		"/(app)/organizations": [12,[2]],
		"/(app)/organizations/invite/[slug]/[code]": [13,[2]],
		"/(app)/profile": [14,[2]],
		"/(app)/[ownerSlug]": [4,[2]],
		"/(app)/[ownerSlug]/[projectSlug]": [5,[2]],
		"/(app)/[ownerSlug]/[projectSlug]/reviews": [6,[2]],
		"/(app)/[ownerSlug]/[projectSlug]/reviews/[branchId]": [7,[2]],
		"/(app)/[ownerSlug]/[projectSlug]/reviews/[branchId]/commit/[changeId]": [8,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';